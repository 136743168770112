
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { trackingOrder } from "../../services/action/action";
import BeatLoader from "react-spinners/BeatLoader";
import "./style.css"
const Pyment = () => {
  useEffect(() => {
    const script = document.createElement('script');
    const link = document.createElement('link');

    script.src = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.js";
    link.href = "https://cdn.moyasar.com/mpf/1.7.3/moyasar.css";
    link.rel = "stylesheet";

    // script.async = true;

    document.body.appendChild(script);
    document.body.appendChild(link);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(link);
    }

  }, []);
  const params = useParams()
  const addorder = useSelector(state => state.addorder)
  const refloc = addorder && addorder.data ? addorder.data.reference : sessionStorage.getItem("referenceOrder")
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    addorder && addorder.data && sessionStorage.setItem("referenceOrder", addorder.data.reference)
  }, [addorder])
  const usertoken = JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    if (params.id == undefined) {
      dispatch(trackingOrder(refloc, usertoken.data.token))
    } else {
      dispatch(trackingOrder(params.id, usertoken.data.token))
    }
    // dispatch(trackingOrder(reference))

  }, [refloc])




  const data = useSelector(state => state.tracking)
  let idreferc = params.id == undefined ? refloc : params.id
  const urlPage = window.location.origin;


  let total = data && data.status && data.data.order.total

  useEffect(() => {
    if (data && data.status && window.Moyasar) {

      window.Moyasar.init({
        // Required
        // Specify where to render the form
        // Can be a valid CSS selector and a reference to a DOM element
        element: '.mysr-form',

        // Required
        // Amount in the smallest currency unit
        // For example:
        // 10 SAR = 10 * 100 Halalas
        // 10 KWD = 10 * 1000 Fils
        // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
        amount: total * 100,

        // Required
        // Currency of the payment transation
        currency: 'SAR',

        // Required
        // A small description of the current payment process
        description: 'Coffee Order #1',

        // Required
        // publishable_api_key: 'pk_test_f25dAmKPUmAMZ4f2FiJGSXrHXfJfwBy9KGHkE7qh',

        publishable_api_key: 'pk_live_2frmQde6jSjnpQC9qTYa9YeDXu5zbEnM58JdhxLC',

        // Required
        // This URL is used to redirect the user when payment process has completed
        // Payment can be either a success or a failure, which you need to verify on you system (We will show this in a couple of lines)
        callback_url: `${urlPage}/CompletPay/${idreferc}`,
        // callback_url:`${urlPage}/#/CompletPay/${idreferc}`,

        // Optional
        // Required payments methods
        // Default: ['creditcard', 'applepay', 'stcpay']
        methods: [
          'creditcard',
          'applepay',
          'stcpay'

        ],
        apple_pay: {
          country: 'SA',
          label: 'Awesome Cookie Store',
          validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
        }
      });
    }

  })

  // sk_test_SZpRwm53HyfoBWCBnQLiiKZESqFTmko9i6S2V9P2
  // pk_test_yoACASWPbYB293ELyB4XoEhF2HLmgkF6R51nwhbL
  return (
    <>
      {data && data.status ? <div className="mysr-form"> </div> :
        <div className='spinner'>
          <BeatLoader color="#36d7b7" margin={8}
            size={20}
          />

        </div>}

    </>
  )
}
export default Pyment