import axios from "axios";

let ECOMMERCE_APi = "https://www.ecommerce.vodoglobal.com";
let ACCOUNTING_APi = "https://accounting.vodoglobal.com";
// let ECOMMERCE_APi = "https://testecommerce.vodoglobal.com";
// let ACCOUNTING_APi = "https://test.vodoglobal.com";
export const Listprodect = () => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/1`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'secure-business-key': "604896399db2b51f268.09361336"
            }

        });
//test
        dispatch({ type: "ALLPRODECTS", prodects: res.data });
    }
}

export const favProductlist = (token) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/favProducts/user_favProductList`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'user-token': token,
                'secure-business-key': "604896399db2b51f268.09361336"
            }

        });

        dispatch({ type: "favProductlist", favProductlist: res.data });
    }
}
export const prodectHomeList = (param) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/1${param}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'secure-business-key': "604896399db2b51f268.09361336"

            }

        });

        dispatch({ type: "prodectHomeList", prodectHomeList: res.data });
    }
}
export const prodectHomeListfav = (token, param) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/1${param}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'secure-business-key': "604896399db2b51f268.09361336",

                'user-token': token
            }

        });

        dispatch({ type: "prodectHomeList", prodectHomeList: res.data });
    }
}
export const faVListprodect = (token) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/1`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'user-token': token,
                'secure-business-key': "604896399db2b51f268.09361336"

            }

        });

        dispatch({ type: "ALLPRODECTS", prodects: res.data });
    }
}

export const faVpresentsprodect = (token, pagenum = 1) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/presents?page=${pagenum}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'user-token': token,
                'secure-business-key': "604896399db2b51f268.09361336"

            }

        });

        dispatch({ type: "ALLpresents", presents: res.data });
    }
}
export const presentsprodect = (pagenum = 1) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/productsList/presents?page=${pagenum}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'secure-business-key': "604896399db2b51f268.09361336"

            }

        });

        dispatch({ type: "ALLpresents", presents: res.data });
    }
}
export const sendEmail = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/email/send`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'apiconnection': 'appmobile',
                'secure-business-key': '604896399db2b51f268.09361336',
            },
            data: {
                ...data
            }

        })


        dispatch({ type: "sendEmail", sendEmail: res.data });
    }
}
export const addNewUser = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/store_ecommerce_customer`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            },
            data: {
                ...data
            }

        })


        dispatch({ type: "SIGNUP", signup: res.data });
    }
}

export const addfavProductItem = (data, token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/favProducts/`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'user-token': token
            },
            data: {
                ...data
            }

        })



        dispatch({ type: "addfavProduct", addfavProduct: res });
    }
}
export const deletefavProductItem = (data, token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/favProducts/deleteItem`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'user-token': token
            },
            data: {
                ...data
            }

        })



        dispatch({ type: "addfavProduct", addfavProduct: res });
    }
}
export const login = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/login_ecommerce_customer`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            }

        }).then((response) => {
            if (response.data && response.data.status == 1) {
                localStorage.setItem("userisLogin", true);
                localStorage.setItem("user", JSON.stringify(response.data));

            }


            return response.data;
        });


        dispatch({ type: "USER", user: res });
    }
}
export const editCustomer = (data, user_token, param) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/edit_customer/${param}`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'secure-business-key': '604896399db2b51f268.09361336',

                'user-token': user_token,
                'Content-Type': 'multipart/form-data'

            }

        }).then((response) => {

            if (response.data && response.data.success == true) {
                localStorage.setItem("user", JSON.stringify(response.data));

            }


            return response.data;
        });


        dispatch({ type: "USER", user: res });
    }
}
export const details = (data) => {

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/productDetails/${data}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        }).then(resp => {


            return resp
        })

        dispatch({ type: "DETAILS", details: res.data });
    }
}
export const user_orders_chart = (data, token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/orders/user_orders_chart`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': token,

            },
            data: data

        }).then((response) => {

            return response.data;
        });


        dispatch({ type: "chart", chart: res });
    }
}
export const favdetails = (data, user_token) => {

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/productDetails/${data}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token
            }
        }).then(resp => {


            return resp
        })

        dispatch({ type: "DETAILS", details: res.data });
    }
}
export const districtsAll = () => {

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/user_districts`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                "secure-business-key": "604896399db2b51f268.09361336"
            }
        })
        dispatch({ type: "DISTRICTS", districts: res.data });
    }
}

export const shippingValue = () => {

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/shipping/shipping_value`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }
        })
        dispatch({ type: "SHIPPING", shipping: res.data });
    }
}

export const citiesAll = () => {

    return async (dispatch) => {
        const res = await axios.get(`${ECOMMERCE_APi}/api/user_cities`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                "secure-business-key": "604896399db2b51f268.09361336"
            }
        })
        dispatch({ type: "CITIES", cities: res.data });
    }
}
export const checkPhone = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/phone_exist`, data, {
            headers: {
                'apikey': 'efe2db322a53'

            },
            data: data

        }).then((response) => {

            return response.data;
        });


        dispatch({ type: "EXITPHONE", exit: res.msg });
    }
}
export const sentsms = (data) => {
    return async (dispatch) => {
        const res = await axios.post(`https://api.oursms.com/api-a/msgs`, data).then((response) => {

            return response.data;
        }).then(res => {

        });

        dispatch({ type: "sentsms", sentsms: res });
    }
}
////////////////


export const addAddress = (data, user_token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/add_address`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'user-token': user_token,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            }

        }).then((response) => {
            if (response && response.data.success == 1) {
                localStorage.setItem("address_user", JSON.stringify(response.data));

            }
            return response.data;

        });
        dispatch({ type: "ADDRESS", address: res.data });
    }
}

export const addOrder = (data, user_token) => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/orders/store_order`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token,
                "secure-business-key": "604896399db2b51f268.09361336"

            }

        }).then((response) => {

            if (response.data && response.data.status) {
                localStorage.removeItem("addNewOrder")
                localStorage.removeItem("items")
                localStorage.removeItem("itemsfree")
                sessionStorage.removeItem("detailsCartTotal")


            }
            localStorage.setItem("testapi", JSON.stringify(response.data))

            return response.data
        })


        dispatch({ type: "ADDORDER", addorder: res });
    }
}


export const userOrders = (user_token, id, numberpage = 1) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/orders/user_orders?page=${numberpage}&user_id=${id}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token,
                "secure-business-key": "604896399db2b51f268.09361336"
            }

        }).then((response) => {
            return response.data
        })
        dispatch({ type: "USERORDERS", userOrders: res });
    }
}

export const detailesOrder = (reference, user_token) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/orders/${reference}`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token,
                "secure-business-key": "604896399db2b51f268.09361336"
            }

        }).then((response) => {
            return response.data
        })
        dispatch({ type: "DETAILESORDER", detailesOrder: res });
    }
}
export const trackingOrder = (reference, user_token) => {

    return async (dispatch) => {

        const res = await axios.get(`${ECOMMERCE_APi}/api/orders/${reference}/tracking`, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'user-token': user_token,
                "secure-business-key": "604896399db2b51f268.09361336"
            }

        }).then((response) => {
            return response.data
        })
        dispatch({ type: "TRACKING", tracking: res });
    }
}



export const restPass = (data) => {
    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/reset_password`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            },
            data: data

        }).then((response) => {

            return response.data;
        });
        dispatch({ type: "RESTAPI", restPass: res });
    }
}
export const shippinCost = (data) => {
    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/shipping/shipping_value`, data, {
            headers: {
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            },
            data: data

        }).then((response) => {

            return response.data;
        });
        dispatch({ type: "SHIPPING", shippinCost: res });
    }
}
export const nempass = (data) => {

    return async (dispatch) => {

        const res = await axios.post(`${ACCOUNTING_APi}/api/customers/forgot_password`, data, {
            headers: {
                'apikey': 'efe2db322a53',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
            },
            data: data

        }).then((response) => {
            return response.data;
        });


        dispatch({ type: "NEWPASS", newpass: res });
    }
}
export const ecommercePayment = (id, user_token, payment_id) => {
    var config = {
        method: 'post',
        url: `${ACCOUNTING_APi}/api/ecommerce_payment/${id}?payment_id=${payment_id}`,
        headers: {
            'apikey': 'efe2db322a53',
            "user-token": user_token,
            "secure-business-key": "604896399db2b51f268.09361336"
        }
    };
    return async (dispatch) => {

        const res = await axios(config).then((response) => {
            return response.data
        })
        dispatch({ type: "ecommercePayment", ecommercePayment: res });
    }
}

export const mosque = () => {

    return async (dispatch) => {

        const res = await axios.post(`${ECOMMERCE_APi}/api/mosque/all`, {}, {
            headers: {
                'secure-business-key': '604896399db2b51f268.09361336',
                'apikey': '5f28583f26a1a',
                'apiconnection': 'appmobile'
            }

        })

        dispatch({ type: "mosque", mosque: res.data });
    }
}

export const isLogin = (data) => {
    return (dispatch) => {
        dispatch({ type: "ISLOGIN", isLogin: data })
    }
}

export const AddCart = (value) => {
    localStorage.setItem("items", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("items"))
    return (dispatch) => {
        dispatch({ type: "ADDITEM", cards: data })
    }
}
export const AddCartfree = (value) => {
    localStorage.setItem("itemsfree", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("itemsfree"))
    return (dispatch) => {
        dispatch({ type: "ADDITEMFREE", cardsfree: data })
    }
}
export const step = (value) => {
    return (dispatch) => {
        dispatch({ type: "step", step: value })
    }
}
export const addressOrder = (value) => {
    sessionStorage.setItem("address_order", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("address_order"))
    return (dispatch) => {
        dispatch({ type: "AddresOrder", AddresOrder: data })
    }
}
export const AddNewOrder = (value) => {
    localStorage.setItem("addNewOrder", JSON.stringify(value))
    const data = JSON.parse(localStorage.getItem("addNewOrder"))
    return (dispatch) => {
        dispatch({ type: "ADDNEWORDER", AddNewOrder: data })
    }
}
export const auth = (value) => {
    return (dispatch) => {
        dispatch({ type: "AUTH", payload: value })
    }
}
export const MapLocal = (value) => {
    return (dispatch) => {
        dispatch({ type: "MapLocal", MapLocal: value })
    }
}
